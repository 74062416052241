import axiosClient from '@/helper/call-center';
import { ICloverChargeMemberProps, ICloverCreateTokenProps, IPrintReceiptProps } from '@/interface/clover';
import { EnumMerchantConfigType } from '@/interface/merchant';

export const CloverApi = {
  createCartToken(params: ICloverCreateTokenProps) {
    return axiosClient.post('/clover/single-payment-token', params);
  },
  chargeGuest(params: ICloverChargeMemberProps) {
    return axiosClient.post('/clover/charge/by-guest', params);
  },
  chargeMember(params: any) {
    const { branchId, ...rest } = params;

    return axiosClient.post(`/transactions/by-branches/clover-charge/${branchId}`, rest);
  },
  getAllCard(branchId: string) {
    return axiosClient.get(`/customer/clover-info/?branch_id=${branchId}`);
  },
  getCloverInfoByBranchMerchant(branch: string, purpose: EnumMerchantConfigType) {
    return axiosClient.get(`/merchant/clover-info/${branch}?purpose=${purpose}`);
  },
  getCloverInfoMembershipCategory(id: string) {
    return axiosClient.get(`merchant/clover-info/by-membership-category/${id}`);
  },
  employeeGetCloverInfoByMemberMerchant(categoryId: string, customerId: string) {
    return axiosClient.get(`merchant/clover-info-by-employee/by-membership-category/${customerId}/${categoryId}`);
  },
  getCloverInfoByBranchMerchantWithEmployee(branch: string, customer: string, purpose: EnumMerchantConfigType) {
    return axiosClient.get(`/merchant/clover-info-by-employee/${customer}/${branch}?purpose=${purpose}`);
  },
  // For Admin
  async adminSavePaymentInfo(params: any) {
    const { id, ...rest } = params;
    return axiosClient.post(`/customer/by-id/${id}/admin-card-register`, rest);
  },

  async adminSavePaymentInfoLocation(params: any) {
    const { memberId, locationId, ...rest } = params;
    return axiosClient.post(`/customer/by-id/${memberId}/${locationId}/admin-card-register`, rest);
  },
  // For member
  async savePaymentInfo(params: any) {
    return axiosClient.post(`/customer/card-register`, params);
  },

  async savePaymentInfoByCategory(id: string, cardData: any) {
    return axiosClient.post(`/customer/card-register/by-membership-category/${id}`, cardData);
  },
  async adminSavePaymentInfoByCategory(category: string, customerId: string, cardData: any) {
    return axiosClient.post(
      `/customer/card-register-by-admin/by-membership-category/${category}/${customerId}`,
      cardData,
    );
  },
  async createCardByBranch(branchId: string, cardData: any): Promise<any> {
    return axiosClient.post(`/customer/card-register/${branchId}`, cardData);
  },

  // For member
  saveGuestPaymentInfo(params: any) {
    return axiosClient.post('/clover/save-guest-payment-info', params);
  },
  deleteCardById(branchId: string) {
    return axiosClient.delete(`/customer/by-id/cards?branch_id=${branchId}`);
  },
  deleteCardByReference(reference: string) {
    return axiosClient.delete(`/customer/by-refer/${reference}`);
  },
  employeeDeleteCardByReference(reference: string, customerId: string) {
    return axiosClient.delete(`/customer/by-refer/${reference}/${customerId}/by-employee`);
  },
  adminDeleteCardById(customerId: string, branchId: string) {
    return axiosClient.delete(`/customer/by-admin/${customerId}/cards/${branchId}`);
  },
  printReceipt(data: IPrintReceiptProps) {
    return axiosClient.post('/clover/print-receipt', data);
  },
};
