import clsx from 'clsx';

const Loading = (props: any) => {
  return (
    <div className={clsx('flex h-[100vh] !w-full flex-1 items-center justify-center', props.className)}>
      <div className={clsx('size-20 animate-spin rounded-full border-8 border-dashed border-zelene-red', props.size)} />
    </div>
  );
};

export default Loading;
