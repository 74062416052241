import { enumActionHistoryLog, enumTableHistoryLog, StatusBookingKey, UserRoleKey } from './enum';

export const convertDayOfWeek = (day: number) => {
  switch (day) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return day;
  }
};

export const convertNameRole = (name: string) => {
  switch (name) {
    case UserRoleKey.ADMINISTRATOR:
      return 'Administrator';
    case UserRoleKey.STAFF:
      return 'Staff';
    case UserRoleKey.SALON_OWNER:
      return 'Salon Owner';
    case UserRoleKey.MANAGER:
      return 'Manager';
    case UserRoleKey.CUSTOMER:
      return 'Customer';
    default:
      return 'Unknown';
  }
};

export function convertToRoman(num: number): string {
  const romanNumerals: { [key: number]: string } = {
    1: 'I',
    4: 'IV',
    5: 'V',
    9: 'IX',
    10: 'X',
    40: 'XL',
    50: 'L',
    90: 'XC',
    100: 'C',
    400: 'CD',
    500: 'D',
    900: 'CM',
    1000: 'M',
  };

  let result: string = '';

  // Lặp qua bảng chuyển đổi từ lớn đến nhỏ
  const keys = Object.keys(romanNumerals)
    .map(Number)
    .sort((a, b) => b - a);

  for (const key of keys) {
    while (num >= key) {
      result += romanNumerals[key];
      num -= key;
    }
  }

  return result;
}

export const convertTypeTransaction = (type: string) => {
  switch (type) {
    case 'CREDIT':
      return 'Credit';
    case 'CASH':
      return 'Cash';
    case 'REFUND':
      return 'Refund';
    case 'DEBIT':
      return 'Debit';
    case 'VISA':
      return 'Visa';
    case 'MASTER':
      return 'Master';
    case 'AMEX':
      return 'Amex';
    case 'PAYMENT_APP':
      return 'Payment App';
    case 'MEMBER_CARD':
      return 'Member Card';
    case 'GIFT_CARD':
      return 'Gift Card';
    case 'CLOVER':
      return 'Clover';
    default:
      return type;
  }
};

export function removeAscent(str: any) {
  if (str === null || str === undefined) return str;
  // str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/Đ/g, 'D');
  return str;
}

export const base64ToBlob = (base64Data: string, mimeType: string = '', chunkSize: number = 8192): Blob => {
  // Validate input
  if (!base64Data) {
    throw new Error('Base64 data is required');
  }

  // Remove data URL prefix if present
  const b64Data = base64Data.replace(/^data:[^;]+;base64,/, '');

  try {
    // Decode base64 and get raw binary data
    const byteCharacters = window.atob(b64Data);
    const byteArrays: Uint8Array[] = [];
    const len = byteCharacters.length;

    // Process data in chunks for better memory usage
    for (let offset = 0; offset < len; offset += chunkSize) {
      const chunk = byteCharacters.slice(offset, offset + chunkSize);
      const byteNumbers = new Uint8Array(chunk.length);

      // Convert to byte array using typed array
      for (let i = 0; i < chunk.length; i++) {
        byteNumbers[i] = chunk.charCodeAt(i);
      }

      byteArrays.push(byteNumbers);
    }

    return new Blob(byteArrays, { type: mimeType });
  } catch (error) {
    throw new Error(`Failed to convert base64 to Blob: ${(error as Error).message}`);
  }
};

export function base64ToFile(base64String: any, fileName: string) {
  // Split the Base64 string to get the MIME type and the data
  const [header, base64Data] = base64String.split(',');
  const mimeType = header.match(/:(.*?);/)[1];

  // Decode the Base64 string
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
  const byteArray = new Uint8Array(byteNumbers);

  // Create a File object
  return new File([byteArray], fileName, { type: mimeType });
}

export function base64ToFileDetail(base64: string, fileName: string, mimeType: string): File {
  const byteString = atob(base64.split(',')[1]);
  const byteNumbers = new Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new File([byteArray], fileName, { type: mimeType });
}

export const convertActionHistoryLog = (action: string) => {
  switch (action) {
    case enumActionHistoryLog.CREATE:
      return 'Create';
    case enumActionHistoryLog.UPDATE:
      return 'Update';
    case enumActionHistoryLog.DELETE:
      return 'Delete';
    case enumActionHistoryLog.CHANGE_STATUS:
      return 'Change status';
    case enumActionHistoryLog.CONFIRM:
      return 'Confirm';
    case enumActionHistoryLog.CANCEL:
      return 'Cancel';
    default:
      return action;
  }
};

export const convertTableHistoryLog = (table: string) => {
  switch (table) {
    case enumTableHistoryLog.ADD_ON:
      return 'Add-on';
    case enumTableHistoryLog.CUSTOMER:
      return 'Customer';
    case enumTableHistoryLog.EMPLOYEE:
      return 'Employee';
    case enumTableHistoryLog.SERVICE:
      return 'Service';
    case enumTableHistoryLog.SERVICE_CATEGORY:
      return 'Service category';
    case enumTableHistoryLog.BOOKING:
      return 'Booking';
    case enumTableHistoryLog.DISCOUNT_CODE:
      return 'Discount code';
    case enumTableHistoryLog.PAYMENT_METHOD:
      return 'Payment method';
    case enumTableHistoryLog.REWARD_CONFIG:
      return 'Reward config';
    case enumTableHistoryLog.STORE_BRANCH:
      return 'Store branch';
    case enumTableHistoryLog.BILL:
      return 'Bill';
    case enumTableHistoryLog.MEMBERSHIP:
      return 'Membership';
    default:
      return table;
  }
};

export const convertStatusBooking = (status: string) => {
  switch (status) {
    case StatusBookingKey.ARRIVED:
      return 'Arrived';
    case StatusBookingKey.CANCELLED:
      return 'Cancelled';
    case StatusBookingKey.COMPLETED:
      return 'Completed';
    case StatusBookingKey.CONFIRMED:
      return 'Confirmed';
    case StatusBookingKey.PENDING:
      return 'Pending';
    case StatusBookingKey.UNCONFIRMED:
      return 'Unconfirmed';
    default:
      return status;
  }
};

export const convertBgColor = (action: string) => {
  switch (action) {
    case enumActionHistoryLog.CREATE:
      return 'bg-[#29751D]';
    case enumActionHistoryLog.UPDATE:
      return 'bg-[#D29A2A]';
    case enumActionHistoryLog.DELETE:
      return 'bg-[#B84E44]';
    case enumActionHistoryLog.CHANGE_STATUS:
      return 'bg-[#F0F9F9]';
    case enumActionHistoryLog.CONFIRM:
      return 'bg-[#1D3F75]';
    case enumActionHistoryLog.CANCEL:
      return 'bg-[#B84E44]';
    default:
      return '';
  }
};

export const convertMonth = (month: number) => {
  switch (month) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'June';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
    default:
      return '';
  }
};
