import { Input } from 'antd';
import FormItem from 'antd/es/form/FormItem';

interface IZipInputProps {
  className?: string;
  size?: 'small' | 'middle' | 'large';
}

const ZipInput = (props: IZipInputProps) => {
  const zipCodePattern = /^(\d{5}$)|(\d{5}-\d{4}$)/;

  const validateZipCode = (_: any, value: string) => {
    if (!value) {
      return Promise.reject('Please enter a ZIP code');
    }
    if (!zipCodePattern.test(value)) {
      return Promise.reject('Please enter a valid ZIP code (12345 or 12345-6789)');
    }
    return Promise.resolve();
  };

  return (
    <FormItem
      label='ZIP Code'
      name={'code'}
      rules={[{ validator: validateZipCode, required: true }]}
      className={props.className}
    >
      <Input placeholder='ZIP Code' size={props.size || 'middle'} />
    </FormItem>
  );
};

export default ZipInput;
