import { CreditCardOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';
import DINERS_CLUB from '../../../../../public/images/DINERS_CLUB.png';
import JCB from '../../../../../public/images/JCB2.png';
import VISA from '../../../../../public/svgs/VISA.svg';
import Amex from '../../../../../public/svgs/american-express.svg';
import DC from '../../../../../public/svgs/discover-svgrepo-com.svg';
import MC from '../../../../../public/svgs/mastercard.svg';

const CARD_TYPES = {
  VISA: { regex: /^4[0-9]{6,}$/, cover: VISA },
  MC: {
    regex: /^5[1-5][0-9]{5,}|^222[1-9][0-9]{3,}|^22[3-9][0-9]{4,}|^2[3-6][0-9]{5,}|^27[01][0-9]{4,}|^2720[0-9]{3,}$/,
    cover: MC,
  },
  AMEX: { regex: /^3[47][0-9]{5,}$/, cover: Amex },
  DISCOVER: { regex: /^6(?:011|5[0-9]{2})[0-9]{3,}$/, cover: DC },
  DINERS_CLUB: { regex: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/, cover: DINERS_CLUB },
  JCB: { regex: /^(?:2131|1800|35\d{3})\d{11}$/, cover: JCB },
};

const CreditCardInput = ({ onCardChange, resetIcon }: any) => {
  const [cardNumber, setCardNumber] = React.useState('');
  const [cardType, setCardType] = React.useState('');

  const detectCardType = (number: any) => {
    for (const [type, { regex }] of Object.entries(CARD_TYPES)) {
      if (regex.test(number)) {
        return type;
      }
    }
    return '';
  };

  React.useEffect(() => {
    if (!cardNumber) {
      setCardType('');
    }
  }, []);

  React.useEffect(() => {
    const type = detectCardType(cardNumber);
    setCardType(type);
    onCardChange({ number: cardNumber, type });
  }, [cardNumber]);

  const handleInputChange = (e: any) => {
    const input = e.target.value.replace(/\D/g, '');
    setCardNumber(input);
  };

  const getSuffix = () => {
    if (cardType && CARD_TYPES?.[cardType]) {
      return (
        <span style={{ color: 'rgba(0,0,0,.45)' }} className='!relative h-[24px] w-[49px]'>
          <Image
            fill
            sizes='49px'
            className={clsx(
              'h-6 !w-[49px] object-contain',
              // (cardType === DC || cardType.cover === Amex) && 'object-cover',
            )}
            src={CARD_TYPES?.[cardType].cover}
            alt='cardTypeIcon'
          />
        </span>
      );
    }

    return <CreditCardOutlined style={{ color: 'rgba(0,0,0,.45)' }} />;
  };

  return (
    <FormItem
      name={'card_number'}
      label={'Card number'}
      rules={[
        { required: true, message: 'Please input your credit card number!' },
        {
          validator: (_, value) => {
            if (!value) {
              return Promise.resolve();
            }
            for (const { regex } of Object.values(CARD_TYPES)) {
              if (regex.test(value)) {
                return Promise.resolve();
              }
            }
            return Promise.reject('Please enter a valid credit card number');
          },
        },
      ]}
    >
      <Input
        type='number'
        size='large'
        prefix={<CreditCardOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        value={cardNumber}
        onChange={handleInputChange}
        placeholder='Enter card number'
        maxLength={16}
        suffix={getSuffix()}
      />
    </FormItem>
  );
};

export default CreditCardInput;
